import { useEffect, useState } from 'react'
import { get } from 'utils/axios'
import { ICompany } from 'redux/interfaces/company'
import Constants from 'server/constants'

export const useHotCompaniesData = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ICompany[]>([])

  const fetchData = async () => {
    setIsLoading(true)
    const { data } = await get('api/company/list', {
      params: {
        limit: Constants.HOT_COMPANIES_DEFAULT_LIMIT,
      },
    })

    if (data.status === 'ok') {
      setData(data.data.companies)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    isLoading,
    data,
  }
}
