import React, { useState } from 'react'
import NewsletterModal from 'components/newsletter/NewsletterModal'
import TrackingUtils from 'utils/tracking'
import Emoji from 'components/Emoji'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  activeCategories?: string[]
}

const NewsletterModalCta: React.FC<IProps> = ({ activeCategories }) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const show = () => {
    setVisible(true)
    TrackingUtils.event('newsletter', { event_action: 'modal-show' })
  }

  const hide = () => {
    setVisible(false)
    TrackingUtils.event('newsletter', { event_action: 'modal-hide' })
  }

  return (
    <>
      <p className="filter__newsletter-link paragraph paragraph--small" onClick={show}>
        <Emoji>📫</Emoji>{' '}
        <span className="filter__newsletter-link--desktop">{t('filter.newsletter')}</span>
      </p>

      {visible && <NewsletterModal activeCategories={activeCategories} onClose={hide} />}
    </>
  )
}

export default NewsletterModalCta
