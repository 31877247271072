import qs from 'qs'
import { getSlug, NODE_ENV } from 'utils/helpers'
import { IJob } from 'redux/interfaces/job'

export default class UrlUtils {
  static getSocialHost() {
    let host =
      NODE_ENV === 'production' ? 'https://pretlak.com' : 'https://staging.pretlak.com'

    return host
  }

  static getCleanJobUrl(job: IJob) {
    if (!job) {
      return ''
    }

    return `/job/${getSlug(job.company_full.slug)}/${getSlug(job.slug)}/${job.shortId}`
  }

  static getJobUrl(job: IJob, customQuery: any = {}) {
    if (!job) {
      return ''
    }

    return `/job/${getSlug(job.company_full.slug)}/${getSlug(job.slug)}/${
      job.shortId
    }${qs.stringify(
      {
        ...customQuery,
        preview:
          job.public || (!job.public && job.status === 'closed') ? undefined : true,
      },
      { addQueryPrefix: true }
    )}`
  }

  static getJobEditUrl(job: IJob, next?: string) {
    if (!job) {
      return ''
    }

    if (next) {
      return `/job/edit/${job.shortId}?next=${next}`
    }

    return `/job/edit/${job.shortId}`
  }

  static getUrlWithoutQuery(url?: string) {
    if (!url) {
      return null
    }

    return url.split(/[?#]/)[0]
  }
}
