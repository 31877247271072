import React, { FC } from 'react'

const PostLoader: FC = () => (
  <div className="magpost">
    <article>
      <div className={`magpost__content`}>
        <div className="magpost__header">
          <div className="magpost__title--loading" />
          <div className="magpost__title--loading magpost__title--loading-size-2" />
          <div className="magpost__title--loading magpost__title--loading-size-3" />
        </div>
      </div>
    </article>
  </div>
)

export default PostLoader
