import React, { PureComponent } from 'react'
import qs from 'qs'
import { get } from 'utils/axios'
import Page from 'pages/index'

const pageConf = {
  context: 'index',
  title: 'home',
  description: 'home',
}

interface IProps {
  jobs?: any
  jobCategories?: any
  jobTypes?: any
  skillLevels?: any
  cities?: any
  tags?: any
  page: number
}

export default class Index extends PureComponent<IProps> {
  static async getInitialProps({ query }) {
    const nextQuery = qs.stringify(
      {
        ...query,
        skip: query.skip || 0,
        page: query.page || 1,
      },
      { addQueryPrefix: true }
    )

    const { data } = await get(`api/job/list/${nextQuery}`)

    if (data.status === 'ko') {
      return { notFound: true }
    }

    const { data: filterData } = await get('api/job/filter/')

    return {
      pageConf,
      filterData,
      jobs: data.jobs,
      total: data.total,
      newTotal: data.newTotal,
      page: query.page ? Number.parseInt(query.page) : 1,
    }
  }

  render() {
    return <Page {...this.props} />
  }
}
