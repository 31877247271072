import React, { FC } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import useTranslation from 'translations/hooks/useTranslation'
import ButtonSecondary from 'components/ButtonSecondary'
import ProFreelancersCarousel from 'pages/freelancers/components/ProFreelancersCarousel'
import type { IStoreState } from 'redux/reducers'

type IProps = {
  topPosition?: boolean
  renderWithoutFold?: boolean
}

const ProFreelancersHomepageBanner: FC<IProps> = ({ topPosition, renderWithoutFold }) => {
  const { t } = useTranslation()
  const freelancersCount = useSelector(
    (state: IStoreState) => state.stats?.freelancers_count?.value
  )

  return (
    <div
      className={classNames('pro-freelancers-homepage-banner', {
        'ad-hp--top': topPosition,
        'ad-hp--without-fold': renderWithoutFold,
      })}
    >
      <div className="relative container flex pro-freelancers-homepage-banner-inner">
        <div className="banner-inner-content">
          <h1
            className="heading-2-5 uppercase company__banner-heading no-margin"
            dangerouslySetInnerHTML={{
              __html: t('freelancers.companyProfileUpsell.title', {
                count: freelancersCount || 1200,
              }),
            }}
          />
          <ButtonSecondary
            color="green"
            text={t('freelancers.companyProfileUpsell.cta')}
            className="margin-top-small"
            href="/freelancers"
          />
        </div>

        <div className="pro-freelancers-homepage-banner-carousel margin-left-15">
          <ProFreelancersCarousel limit={3} isStatic gap={15} />
        </div>
      </div>
    </div>
  )
}

export default ProFreelancersHomepageBanner
