import React, { FC } from 'react'
import dayjs from 'dayjs'
import Link from 'next/link'
import Image from 'next/image'
import IPost from 'redux/interfaces/magazine/post'
import JobUtils from 'utils/job'

type IProps = IPost

const Post: FC<IProps> = ({
  title,
  slug,
  featured_image,
  published_at,
  category,
  status,
}) => {
  return (
    <Link href={`/mag/${slug}`} className="magpost">
      <article>
        <div className="magpost__image-wrapper">
          <Image
            src={featured_image.thumbnail || '/images/mag/post-placeholder.png'}
            // width={360}
            // height={200}
            alt={title}
            sizes="(max-width: 768px) 100vw,
            (max-width: 1200px) 50vw,
            33vw"
            fill
          />

          <div className="magpost__footer">
            {status === 'draft' && <p className="pill pill--orange pill--small">Draft</p>}

            {new Date(published_at) > JobUtils.getIsNewLimitDate() && (
              <p className="pill pill--orange pill--small">Nový</p>
            )}

            <Link href={`/mag/kategoria/${category.slug}`} legacyBehavior>
              <span className="pill pill--small pill--tag pill--link">
                {category.name}
              </span>
            </Link>

            <div className="pill pill--small pill--white">
              {dayjs(published_at).format('D.M.YYYY')}
            </div>
          </div>
        </div>

        <div className={`magpost__content bg--${category.slug}`}>
          <div className="magpost__header">
            <h3 className="magpost__title">{title}</h3>
          </div>
        </div>
      </article>
    </Link>
  )
}

export default Post
