export type FilterBase = {
  _id: string
  name: string
  slug: string
  shortName?: string
}

export type Country = FilterBase & {
  create_date: string
}

export type City = FilterBase & {
  create_date: string
  isRemote: number
  country: string
}

export type JobType = FilterBase & {
  create_date: string
  isFreelance: number
}

export type Tag = FilterBase & {
  category: Category
}

export type SkillLevel = FilterBase & {
  position: number
}

export type JobCategory = FilterBase

export type Category = FilterBase

export type FilterQuery = {
  jobCategories: string[]
  cities: string[]
  countries: string[]
  jobTypes: string[]
  skillLevels: string[]
  tags: string[]
  maxAge: string[]
  available?: string
  pro?: string
  paymentTypes?: string[]
  searchTerm?: string
  salary?: string[]
}

export enum PaymentType {
  Tpp = 'tpp',
  Invoice = 'invoice',
}

export type FilterQueryData = {
  jobCategories?: string[]
  cities?: string[]
  countries?: string[]
  jobTypes?: string[]
  skillLevels?: string[]
  tags?: string[]
  maxAge?: string[]
  available?: string
  pro?: string
  showAll?: string
  paymentTypes?: string[]
  searchTerm?: string
  salary?: string[]
}

export interface IFilterData {
  jobTypes?: JobType[]
  cities?: City[]
  countries?: Country[]
  jobCategories?: JobCategory[]
  skillLevels?: SkillLevel[]
  tags?: Tag[]
  paymentTypes?: FilterBase[]
  salary?: any
  others?: (FilterBase & { isBoolean?: boolean })[]
  availabilities?: FilterBase[]
}

export type FilterContext = 'jobs' | 'freelancers' | 'companies'
