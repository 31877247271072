import { useEffect, useState } from 'react'
import { get } from 'utils/axios'
import { IProfile } from 'redux/interfaces/profile'

export const useRandomProFreelancersData = (options?: { limit?: number }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IProfile[]>([])

  const fetchData = async () => {
    setIsLoading(true)
    const { data } = await get('api/profile/pro-homepage-freelancers', {
      params: {
        limit: options?.limit,
      },
    })

    if (data.status === 'ok') {
      setData(data.data)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    isLoading,
    data,
  }
}
