import React, { FC, useState } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import Emoji from 'components/Emoji'
import useTranslation from 'translations/hooks/useTranslation'

const TAG_LIMIT = 4

type IProps = {
  tags: any[]
  identifier: string
  onTagClick: (value: string) => void
  limit?: number
  className?: string
  skillLevels?: any[]
  onSkillLevelClick?: (value: string) => void
}

const SingleTag: FC<{
  slug: string
  text: string
  onClick: (value: string) => void
  reverseColor?: boolean
  href?: string
}> = ({ slug, text, onClick, reverseColor, href }) => {
  const className = classNames('pill pill--tag pill--small pill--link', {
    'pill--dark-hoverable': reverseColor,
  })

  if (onClick) {
    return (
      <span
        className={className}
        onClick={(e) => {
          e.preventDefault()
          onClick(slug)
        }}
      >
        {text}
      </span>
    )
  }

  return (
    <Link href={href} legacyBehavior>
      <span className={className}>{text}</span>
    </Link>
  )
}

const ExpandableTags: FC<IProps> = ({
  tags = [],
  skillLevels = [],
  identifier,
  onTagClick,
  onSkillLevelClick,
  limit = TAG_LIMIT,
  className,
}) => {
  const { t } = useTranslation()
  const [isShownAll, setIsShownAll] = useState(false)

  const visibleSkillLevels = isShownAll ? skillLevels : skillLevels.slice(0, limit)
  const visibleTags = isShownAll ? tags : tags.slice(0, limit - visibleSkillLevels.length)

  const handleMoreClick = (e) => {
    e.preventDefault()
    setIsShownAll(true)
  }

  return (
    <div className={classNames('pills-wrapper', className)}>
      <Emoji>
        {visibleSkillLevels &&
          visibleSkillLevels.map((sl) => {
            const text = t(`freelancers.skillLevel.plain.${sl.slug}`)
            if (!text) return null

            return (
              <SingleTag
                key={`${identifier}-${sl._id}`}
                onClick={onSkillLevelClick}
                slug={sl.slug}
                text={text}
                reverseColor={true}
                href={`/?skillLevels=${sl.slug}`}
              />
            )
          })}
        {visibleTags &&
          visibleTags.length > 0 &&
          visibleTags.map((tag) => {
            return (
              <SingleTag
                key={`${identifier}-${tag._id}`}
                onClick={onTagClick}
                slug={tag.slug}
                text={tag.slug === 'manifest' ? t('manifest.tag') : tag.name}
                href={`/?tags=${tag.slug}`}
              />
            )
          })}
      </Emoji>

      {!isShownAll && skillLevels.length + tags.length > limit && (
        <span
          key={`${identifier}-more`}
          onClick={handleMoreClick}
          className="pill pill--tag pill--small pill--link"
        >
          ...
        </span>
      )}
    </div>
  )
}

export default ExpandableTags
