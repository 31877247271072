import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { isEmailValid } from 'utils/form'
import Translate from 'translations/Translate'
import { GrammarCaseKey } from 'translations/types'
import StringUtils from 'utils/string'
import TrackingUtils from 'utils/tracking'

interface IProps {
  checkedDev?: boolean
  checkedDesign?: boolean
  checkedMarketing?: boolean
  checkedCreative?: boolean
  checkedNonTech?: boolean
  withoutMargin?: boolean
  className?: string
}

interface IState {
  value: string
  error: boolean
  errorKey: string | null
}

export default class NewsletterInput extends PureComponent<IProps, IState> {
  form
  categoryDev
  categoryDesign
  categoryMarketing
  categoryCreative
  categoryNonTech

  state: IState = {
    value: '',
    error: false,
    errorKey: null,
  }

  componentDidMount(): void {
    const {
      checkedDev,
      checkedDesign,
      checkedMarketing,
      checkedCreative,
      checkedNonTech,
    } = this.props

    if (checkedDev) {
      this.categoryDev.checked = true
    }
    if (checkedDesign) {
      this.categoryDesign.checked = true
    }
    if (checkedMarketing) {
      this.categoryMarketing.checked = true
    }
    if (checkedCreative) {
      this.categoryCreative.checked = true
    }
    if (checkedNonTech) {
      this.categoryNonTech.checked = true
    }
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { value } = this.state

    if (
      !this.categoryDev.checked &&
      !this.categoryDesign.checked &&
      !this.categoryMarketing.checked &&
      !this.categoryCreative.checked &&
      !this.categoryNonTech.checked
    ) {
      return this.setState({
        error: true,
        errorKey: 'common.error.selectAtLeastOneOption',
      })
    } else if (value.length === 0) {
      return this.setState({ error: true, errorKey: 'common.requiredField' })
    } else if (!isEmailValid(value)) {
      return this.setState({ error: true, errorKey: 'common.error.wrongFormat' })
    } else {
      TrackingUtils.event('newsletter', { event_action: 'submit' })
      this.form.submit()
    }
  }

  render() {
    const { withoutMargin, className } = this.props
    const { value, error, errorKey } = this.state
    const translationVariables = { grammarCase: GrammarCaseKey.Other }

    return (
      <Translate>
        {(t) => (
          <div
            className={classnames(
              {
                'margin-top': !withoutMargin,
              },
              className
            )}
          >
            <div id="tlacenka" className="newsletter">
              <p className="heading-1">{t('newsletter.title')}</p>
              <label className="paragraph newsletter__text" htmlFor="mce-EMAIL">
                {t('newsletter.introduction')}
              </label>
              <form
                className={classnames({
                  'newsletter--error': error,
                })}
                action="https://pretlak.us18.list-manage.com/subscribe/post?u=218e26213c6c0080107a98713&amp;amp;id=be645fceb7"
                method="POST"
                onSubmit={this.onSubmit}
                ref={(el) => {
                  this.form = el
                }}
              >
                <div className="newsletter__checkboxes">
                  <div className="input-wrapper">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      value="1"
                      name="group[5547][1]"
                      id="mce-group[5547]-5547-0"
                      ref={(el) => {
                        this.categoryMarketing = el
                      }}
                    />
                    <label htmlFor="mce-group[5547]-5547-0">
                      {StringUtils.capitalize(
                        t('freelancers.categoryName.marketeri', translationVariables)
                      )}
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      value="1"
                      name="group[5547][2]"
                      id="mce-group[5547]-5547-1"
                      ref={(el) => {
                        this.categoryDesign = el
                      }}
                    />
                    <label htmlFor="mce-group[5547]-5547-1">
                      {StringUtils.capitalize(
                        t('freelancers.categoryName.dizajneri', translationVariables)
                      )}
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      value="1"
                      name="group[5547][4]"
                      id="mce-group[5547]-5547-2"
                      ref={(el) => {
                        this.categoryCreative = el
                      }}
                    />
                    <label htmlFor="mce-group[5547]-5547-2">
                      {StringUtils.capitalize(
                        t('freelancers.categoryName.kreativci', translationVariables)
                      )}
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      value="1"
                      name="group[5547][8]"
                      id="mce-group[5547]-5547-3"
                      ref={(el) => {
                        this.categoryDev = el
                      }}
                    />
                    <label htmlFor="mce-group[5547]-5547-3">
                      {StringUtils.capitalize(
                        t('freelancers.categoryName.developeri', translationVariables)
                      )}
                    </label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      value="1"
                      name="group[5547][16]"
                      id="mce-group[5547]-5547-4"
                      ref={(el) => {
                        this.categoryNonTech = el
                      }}
                    />
                    <label htmlFor="mce-group[5547]-5547-4">
                      {StringUtils.capitalize(
                        t('freelancers.categoryName.nontech', translationVariables)
                      )}
                    </label>
                  </div>
                </div>
                <div className="input-wrapper newsletter__input">
                  <input
                    className="input input--with-button"
                    type="text"
                    placeholder={t('common.placeholder.yourEmail')}
                    name="EMAIL"
                    id="mce-EMAIL"
                    value={value}
                    onChange={this.onChange}
                  />
                  <input
                    className="input__button"
                    id="newsletter__submit"
                    type="submit"
                    value={t('common.actions.submit')}
                  />
                  {error && (
                    <p className="input__error-text input__error-text--newsletter input__error-text--visible">
                      {t(errorKey || 'common.requiredField')}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </Translate>
    )
  }
}
