import validator from 'validator'

export const isUrlValid = (url: string): boolean => {
  return validator.isURL(url)
}

export const isEmailValid = (email: string): boolean => {
  return validator.isEmail(email)
}

const VALID_TELEPHONE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

export const isTelephoneValid = (telephone: string): boolean => {
  return VALID_TELEPHONE_REGEX.test(telephone)
}
