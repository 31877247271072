import { useEffect } from 'react'
import { setToLocalStorage } from 'utils/local-storage'
import type { FilterQuery } from 'types/filter'

interface IProps {
  storageKey: string
  query: Partial<FilterQuery>
}

export default function FilterLocalStorage({ storageKey, query }: IProps) {
  useEffect(() => {
    const hasAnyFilter = Object.keys(query).some((key: string) => {
      const filter = query[key]
      return Boolean(filter?.length)
    })
    // NOTE: We want to save only query with active filters.
    // Otherwise, the saved filter could be overwritten with empty settings.
    if (hasAnyFilter) {
      setToLocalStorage(storageKey, query)
    }
  }, [storageKey, query])

  return null
}
