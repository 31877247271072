import Head from 'next/head'
import { useRouter } from 'next/router'
import OgUtils from 'utils/og'

import useTranslation from 'translations/hooks/useTranslation'
import type {
  City,
  Country,
  FilterQuery,
  JobCategory,
  JobType,
  SkillLevel,
  Tag,
} from 'types/filter'

interface IProps {
  jobCategories: JobCategory[]
  jobTypes: JobType[]
  skillLevels: SkillLevel[]
  cities: City[]
  countries: Country[]
  tags: Tag[]
  query: FilterQuery
  total: number
}

const HomepageMeta = ({ query, cities, countries, tags, total }: IProps) => {
  const router = useRouter()
  const { t, locale } = useTranslation()
  const title = OgUtils.getQueryTitle(
    {
      query,
      citiesData: cities,
      countriesData: countries,
      tagsData: tags,
      total,
    },
    t
  )
  const ogImage = OgUtils.getCategoryOgImage(query, tags, locale)

  return (
    <Head>
      <title>{title}</title>
      <meta key="title" property="og:title" content={title} />
      <meta key="image" property="og:image" content={ogImage} />
      <meta key="url" property="og:url" content={OgUtils.getHomepageUrl(router)} />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:image" name="twitter:image" content={ogImage} />
    </Head>
  )
}

export default HomepageMeta
