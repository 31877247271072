export const LocalStorageKey = {
  PAYMENT_PRODUCTS: 'payment-products',
}

export function setToLocalStorage(key: string, data: unknown) {
  try {
    const serializedData = JSON.stringify(data)
    localStorage.setItem(key, serializedData)
  } catch (error) {
    console.error('Failed to set local storage data:', error)
  }
}

export function removeFromLocalStorage(key: string) {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('Failed to remove local storage item:', error)
  }
}

export function getFromLocalStorage<T>(key: string): T | null {
  try {
    const data = localStorage.getItem(key)
    const deserializedData = JSON.parse(data)

    return deserializedData
  } catch (error) {
    console.error(`Failed to get local storage data for "${key}" key:`, error)
    return null
  }
}
