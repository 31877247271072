import React, { FC } from 'react'
import classNames from 'classnames'
import Emoji from 'components/Emoji'
import useTranslation from 'translations/hooks/useTranslation'
import { useRandomProFreelancersData } from 'hooks/freelancers/useRandomProFreelancersData'
import ArrayUtils from 'utils/array'

type IProps = {
  limit?: number
  isStatic?: boolean
  gap?: number
}

const ProFreelancersCarousel: FC<IProps> = ({ limit = 12, isStatic, gap = 30 }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useRandomProFreelancersData({
    limit,
  })

  return (
    <div
      className={classNames('freelancers__carousel', {
        'freelancers__carousel--static': isStatic,
      })}
    >
      <div className="freelancers__carousel-inner" style={{ gap }}>
        {isLoading && (
          <>
            {ArrayUtils.generateArray(limit).map((i) => (
              <div key={i} className="freelancers__carousel-inner-item" />
            ))}
          </>
        )}
        {!isLoading && data && (
          <>
            {data.map((profile, index) => (
              <div
                key={`${profile._id}-${index}`}
                className="freelancers__carousel-inner-item"
                style={{ backgroundImage: `url(${profile.picture})` }}
              >
                <div className="freelancers__carousel-inner-item-overlay" />
                <div className="freelancers__carousel-inner-item-info-wrapper">
                  <div className="pill pill--small pill--white margin-bottom-super-small pill--font-bold">
                    <Emoji>{t('freelancers.pro.label')}</Emoji>
                  </div>
                  <span className="freelancers__carousel-inner-item-info-label">
                    {profile.name}
                  </span>
                  <span className="freelancers__carousel-inner-item-info-label">
                    {profile.title}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default ProFreelancersCarousel
