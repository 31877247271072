import { FC } from 'react'
import Link from 'next/link'
import useTranslation from 'translations/hooks/useTranslation'
import { useHotCompaniesData } from 'hooks/companies/useHotCompaniesData'
import Emoji from 'components/Emoji'
import { ICompany } from 'redux/interfaces/company'
import CompanUtils from 'utils/company'
import ArrayUtils from 'utils/array'

const LoaderSkeleton: FC = () => (
  <>
    {ArrayUtils.generateArray(4).map((_, index) => (
      <div key={index} className="hot-company-item hot-company-item__loader" />
    ))}
  </>
)

const HotCompanyItem: FC<{
  company: ICompany
}> = ({ company }) => {
  const { t } = useTranslation()

  return (
    <Link href={`/company/${company.slug}`} className="hot-company-item">
      <img
        className="company__logo"
        loading="lazy"
        src={`${company.about.logo}_360x360`}
        alt={company.name}
      />
      <div className="hot-company-item__info-wrapper">
        {CompanUtils.isHot(company) && (
          <span>
            <span className="pill pill--super-small">
              <Emoji>{t('label.hot')}</Emoji>
            </span>
          </span>
        )}
        <span className="hot-company-item__name">{company.name}</span>
        <span className="hot-company-item__jobs">
          {t('common.offersCount', {
            count: company.jobs,
          })}
        </span>
      </div>
    </Link>
  )
}

const HomePageHotCompanies: FC = () => {
  const { isLoading, data } = useHotCompaniesData()

  return (
    <div className="hot-companies">
      {isLoading ? (
        <LoaderSkeleton />
      ) : (
        data.map((company) => <HotCompanyItem key={company.slug} company={company} />)
      )}
    </div>
  )
}

export default HomePageHotCompanies
