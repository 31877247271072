import React from 'react'
import PlainModal from 'components/modals/PlainModal'
import Newsletter from 'components/newsletter/Newsletter'

interface IProps {
  activeCategories: string[]
  onClose: () => void
}

const NewsletterModal: React.FC<IProps> = ({ activeCategories, onClose }) => {
  return (
    <PlainModal className="newsletter-modal" onClose={onClose} visible>
      <Newsletter
        checkedDev={activeCategories?.includes('developeri')}
        checkedDesign={activeCategories?.includes('dizajneri')}
        checkedMarketing={activeCategories?.includes('marketeri')}
        checkedCreative={activeCategories?.includes('kreativci')}
        checkedNonTech={activeCategories?.includes('nontech')}
        withoutMargin
      />
    </PlainModal>
  )
}

export default NewsletterModal
