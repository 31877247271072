import React, { PureComponent } from 'react'
import classnames from 'classnames'

interface IProps {
  visible: boolean
  onClose?: (e) => void
  children?: any
  className?: string
  allowScroll?: boolean
}

interface IState {
  width: number
  height: number
}

export default class PlainModal extends PureComponent<IProps> {
  divRef = React.createRef<HTMLDivElement>()

  state: IState = {
    width: 0,
    height: 0,
  }

  handleEscClose = (e) => {
    if (e.keyCode === 27) {
      if (this.props.onClose) {
        this.props.onClose(e)
      }
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      if (!this.props.allowScroll) {
        document.body.classList.add('modal-visible')
      }

      document.addEventListener('keydown', this.handleEscClose, false)
    }

    if (this.divRef.current) {
      this.setState({
        width: this.divRef.current.clientWidth,
        height: this.divRef.current.clientHeight,
      })
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.divRef.current) {
      this.setState({
        width: this.divRef.current.clientWidth,
        height: this.divRef.current.clientHeight,
      })
    }

    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        if (!this.props.allowScroll) {
          document.body.classList.add('modal-visible')
        }

        document.addEventListener('keydown', this.handleEscClose, false)
      } else {
        if (!this.props.allowScroll) {
          document.body.classList.remove('modal-visible')
        }

        document.removeEventListener('keydown', this.handleEscClose, false)
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.allowScroll) {
      document.body.classList.remove('modal-visible')
    }

    document.removeEventListener('keydown', this.handleEscClose, false)
  }

  close = (e) => {
    e.preventDefault()

    if (this.props.onClose) {
      this.props.onClose(e)
    }
  }

  render() {
    const {
      children,
      className,

      onClose,
    } = this.props
    return (
      <div
        className={classnames('small-modal__wrapper', {
          [className]: className,
        })}
      >
        <div onClick={this.close} className="small-modal__backdrop" />

        <div className="small-modal small-modal--plain" ref={this.divRef}>
          {onClose && (
            <div
              data-cy="modal-close-button"
              onClick={this.close}
              className="modal__header-close modal__header-close--absolute"
            >
              <span className="button__in">
                <span className="button__in-icon">+</span>
              </span>
            </div>
          )}

          {children}
        </div>
      </div>
    )
  }
}
