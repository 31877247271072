import React from 'react'
import classnames from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import Emoji from 'components/Emoji'
import JobUtils from 'utils/job'
import ImageUtils from 'utils/image'
import UrlUtils from 'utils/url'
import { IJob } from 'redux/interfaces/job'
import { TranslationFunction } from 'translations/types'
import ArrayUtils from 'utils/array'
import useTranslation from 'translations/hooks/useTranslation'
import ExpandableTags from 'components/ExpandableTags'

interface IProps {
  job: IJob
  allowPreview?: boolean
  adminView?: boolean
  onTag?: (value: string) => void
  onSkillLevel?: (value: string) => void
}

const Job: React.FC<IProps> = ({ job, allowPreview, adminView, onTag, onSkillLevel }) => {
  const { t } = useTranslation()

  const getNewTags = (t?: TranslationFunction) => {
    if (job.sponsored) {
      return (
        <>
          <span className="pill pill--white pill--small">{t('label.sponsored')}</span>
          {new Date(job.create_date) > JobUtils.getIsNewLimitDate() ? (
            <span className="pill pill--orange pill--small">{t('label.newF')}</span>
          ) : (
            <br />
          )}
        </>
      )
    }

    if (JobUtils.isAddonTopActive(job) || JobUtils.isExclusiveTopActive(job)) {
      return (
        <>
          {JobUtils.isAddonTopActive(job) && (
            <span className="pill pill--yellow pill--small">{t('label.top')}</span>
          )}
          {JobUtils.isExclusiveTopActive(job) && (
            <span className="pill pill--white pill--small">{t('label.exclusive')}</span>
          )}

          {new Date(job.create_date) > JobUtils.getIsNewLimitDate() ? (
            <span className="pill pill--orange pill--small">{t('label.newF')}</span>
          ) : (
            <br />
          )}
        </>
      )
    }

    return (
      <>
        {new Date(job.create_date) > JobUtils.getIsNewLimitDate() && (
          <span className="pill pill--orange pill--small">{t('label.newF')}</span>
        )}
        {/* {new Date(job.create_date) > JobUtils.getIsNewLimitDate() ? (
          <span className="pill pill--orange pill--small">{t('label.newF')}</span>
        ) : (
          dayjs(job.update_date || job.create_date).fromNow(true)
        )} */}
        <br />
      </>
    )
  }

  if (!job.slug) {
    return null
  }

  let url = allowPreview ? UrlUtils.getJobUrl(job) : UrlUtils.getCleanJobUrl(job)
  let locations =
    job.job_location && job.job_location.length > 0
      ? job.job_location
      : job.company.location

  let jobClassName = 'job--level-' + job.job_level

  if (JobUtils.isExclusiveTopActive(job)) {
    jobClassName = 'job--level-3'
  } else if (
    JobUtils.isExpired(job) ||
    !JobUtils.isPremiumOrExclusivePositionActive(job)
  ) {
    jobClassName = 'job--level-2'
  } else if (JobUtils.isAddonTopActive(job)) {
    jobClassName = 'job--level-1'
  }

  const logoVisible = JobUtils.isTopActive(job) || JobUtils.isPremiumOrExclusive(job)

  if (logoVisible) {
    jobClassName += ' job--with-logo'
  }

  const backgroundColor = job.addons.background || job.background
  const jobTags = ArrayUtils.sortByValue(job.tags, 'slug', 'manifest')
  const jobSkillLevels = job.skillLevels.sort((a, b) => a.position - b.position)

  const getAdminPanel = () => {
    const email = job.email || job.company_full.email

    return (
      <div className="job__content-date">
        <a
          className="job__info job__info-link"
          href={`/admin/job/edit/${job._id}`}
          onClick={(e) => e.stopPropagation()}
        >
          Upraviť
        </a>

        <p className="job__info">
          CV: <span className="job__info--light">{job.stats.cv}</span>
        </p>
        <p className="job__info">
          Views: <span className="job__info--light">{job.stats.views}</span>
        </p>
        <a
          className="job__info job__info-link"
          href={`mailto:${email}`}
          title={email}
          onClick={(e) => e.stopPropagation()}
        >
          {email}
        </a>
        {job.telephone && (
          <p className="job__info">
            <span className="job__info--light">{job.telephone}</span>
          </p>
        )}
      </div>
    )
  }

  return (
    <div key={'job-item-' + job._id} className="container job-container">
      <Link
        href={url}
        className={classnames('job', {
          'job--dark-bg-variant': ImageUtils.getContrast(backgroundColor) === 'white',
          [jobClassName]: Boolean(jobClassName),
        })}
        style={{ background: backgroundColor }}
      >
        {logoVisible && (
          <div className="job__logo-wrapper">
            <Image
              quality={100}
              width={80}
              height={80}
              className="job__logo"
              src={`${job.company_job_logo || job.company_full.about.logo}_360x360`}
              alt={`${job.name} - ${
                job.company_public_name || job.company_full.name
              } logo`}
            />
          </div>
        )}
        <div className="job__content-left">
          <p className="job__title">
            <Emoji>{job.company_public_name || job.company_full.name}</Emoji>
          </p>

          <p className="job__subtitle">
            <Emoji>{job.name}</Emoji>
          </p>

          <ExpandableTags
            onTagClick={onTag}
            onSkillLevelClick={onSkillLevel}
            identifier={job._id}
            tags={jobTags}
            skillLevels={jobSkillLevels}
          />
        </div>
        <div className="job__content-right-wrapper">
          <div className="job__content-right">
            <p className="job__info job__info-desktop">
              {locations.map((loc, index) => {
                return index === 0 ? loc.name : ', ' + loc.name
              })}
            </p>

            <p className="job__info job__info-desktop">{job.job_type.name}</p>

            <p className="job__info job__info-desktop">
              {JobUtils.hasSalary(job) && JobUtils.getSalary(job, t)}
            </p>

            <p className="job__info job__info-mobile">
              {locations.map((loc, index) => {
                return index === 0 ? loc.name : ', ' + loc.name
              })}
              {JobUtils.hasSalary(job) && ` / ${JobUtils.getSalary(job, t)}`}
            </p>
          </div>

          {adminView ? (
            getAdminPanel()
          ) : (
            <div className="job__content-date">
              <p className="job__info job__info--light">
                {getNewTags(t)}
                <br />
                <br />
              </p>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default Job
