import axios from 'axios'
import axiosRetry from 'axios-retry'
import { NODE_ENV } from 'utils/helpers'

const getWordpressApi = axios.create({
  baseURL: 'https://content.pretlak.sk/wp-json/magazine/v1',
  method: 'get',
  params: {
    showDrafts: Number(NODE_ENV !== 'production'),
  },
})

axiosRetry(getWordpressApi, { retries: 3 })

export { getWordpressApi }
