import classnames from 'classnames'
import Link from 'next/link'
import TrackingUtils from 'utils/tracking'
import Emoji from 'components/Emoji'

interface IProps {
  text: string
  className?: string
  loading?: boolean
  progress?: number
  disabled?: boolean
  size?: 'big'
  href?: any
  color?: string
  onClick?: (e) => void
}

const ButtonSecondary = ({
  text,
  loading,
  progress,
  disabled,
  className,
  href,
  color,
  size,
  onClick,
}: IProps) => {
  const handleClick = (e) => {
    TrackingUtils.event('click-button', {
      event_action: href,
      event_data1: text,
      targetUrl: href,
      location: document.location.href,
      event_data2: `location:${document.location.href}`,
      text,
      event_data3: `text:${text}`,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const buttonClassNames = classnames('button-secondary', {
    'button-secondary--loading': loading,
    'button-secondary--big': size === 'big',
    'button-secondary--orange': color === 'orange',
    'button-secondary--orange-white': color === 'orange-white',
    'button-secondary--black': color === 'black',
    'button-secondary--black-white': color === 'black-white',
    'button-secondary--black-green': color === 'black-green',
    'button-secondary--white': color === 'white',
    'button-secondary--pink': color === 'pink',
    'button-secondary--green': color === 'green',
    'button-secondary--disabled': disabled,
    [className]: className,
  })

  if (href) {
    return (
      <Link href={href} className={buttonClassNames} onClick={handleClick}>
        <span className="button__content">{text}</span>
      </Link>
    )
  }

  return (
    <button
      className={buttonClassNames}
      disabled={disabled || loading}
      onClick={handleClick}
    >
      <span className="button__content">
        {progress ? (
          <>
            <span
              className="button__content--percentage"
              style={{ width: `${progress || 0}%` }}
            />
            <span className="button__content-loader-wrapper">
              <span className="button__content--rotate">
                <Emoji>⏳</Emoji>
              </span>{' '}
              {progress}%
            </span>
          </>
        ) : (
          <span className="button__content-loader button__content--rotate">
            <Emoji>⏳</Emoji>
          </span>
        )}
        <span className="button__content-text">{text}</span>
      </span>
    </button>
  )
}

export default ButtonSecondary
