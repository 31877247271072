const ArrayUtils = {
  generateArray: (length: number) => {
    return Array.from(Array(length).keys())
  },
  sortByValue: <T extends { [x: string]: any }>(
    data: T[],
    key: string,
    value: string
  ) => {
    const output = [...data]

    output.sort((a, b) => {
      if (a[key] === value) {
        return -1
      }
      if (b[key] === value) {
        return 1
      }
      return 0
    })

    return output
  },
}

export default ArrayUtils
