import React, { PureComponent } from 'react'
import Link from 'next/link'
import { getWordpressApi } from 'api/wordpress'
import Translate from 'translations/Translate'
import Post from './Post'
import PostLoader from './PostLoader'
import type IPost from 'redux/interfaces/magazine/post'

interface IProps {}

interface IState {
  posts: IPost[]
  loading: boolean
}

export default class LatestPosts extends PureComponent<IProps, IState> {
  state: IState = {
    posts: [],
    loading: true,
  }

  async componentDidMount() {
    try {
      const { data: recentPosts } = await getWordpressApi({ url: `/recent-posts/` })
      this.setState({ posts: recentPosts, loading: false })
    } catch (e) {
      console.error('Error during the fetching of the posts.')
    }
  }

  render() {
    const { loading, posts } = this.state
    return (
      <Translate>
        {(t) => (
          <div className="mag-latest-posts">
            <div className="container">
              <h3 className="heading-2-5 uppercase center no-margin-bottom margin-top-small">
                {t('magazine.latestPosts')}
              </h3>
            </div>
            <div className="container mag-latest-posts__wrapper">
              {loading && (
                <>
                  <PostLoader />
                  <PostLoader />
                  <PostLoader />
                </>
              )}
              {!loading && posts?.map((post: IPost) => <Post key={post.id} {...post} />)}
            </div>

            <p className="paragraph paragraph--small paragraph--bold margin-bottom-small paragraph--mobile-lineheight center">
              <Link href="/mag" className="link--black">
                {t('magazine.showAllArticles')}
              </Link>
            </p>
          </div>
        )}
      </Translate>
    )
  }
}
