import React, { FC } from 'react'
import useTranslation from 'translations/hooks/useTranslation'
import TrackingUtils from 'utils/tracking'

type IProps = {
  context: string
}

const ContactButton: FC<IProps> = ({ context }) => {
  const { t } = useTranslation()
  const handleClick = () => {
    TrackingUtils.event('contact-button', {
      event_action: context,
      context,
    })
  }

  return (
    <div className="feedback" onClick={handleClick}>
      <p className="paragraph">
        <a className="link--black" href="mailto:pre@pretlak.sk?subject=Kontakt Pretlak">
          {t('common.contact')}
        </a>
      </p>
    </div>
  )
}

export default ContactButton
